import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import DepositListPage from "../components/robot/pages/DepositListPage"


// markup
const DepositList = () => {
    return (
        <React.Fragment>
            <SharedHeader />
            <DepositListPage />
        </React.Fragment>
    )
}

export default DepositList
